.tool-tip {
  display: flex;
  align-items: center;
}

.tool-tip-img {
  height: 29px;
  width: 29px;
}

.react-tooltip {
  white-space: pre-wrap;
  width: 30%;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  background-color: var(--fds-color--primary);
}
