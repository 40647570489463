.lincoln-allocation-methodology {
  width: 90%;
  text-align: left;
  margin: auto;
}

.orange-line {
  border-left: 0.25rem solid var(--fds-color--secondary);
  margin-right: 1rem;
}

.lincoln-allocation-header {
  font-size: 20px;
  font-weight: 300;
}

.lincoln-allocation-text {
  font-size: 14px;
  font-weight: 300;
}
