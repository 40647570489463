@import "globalVariables";
#faq {
  .question {
    margin-bottom: 0.3em;
    color: $primary-color;
    border: 1px var(--fds-color--primary) solid;
  }

  .lincoln-question {
    margin-bottom: 0.3em;
    color: $lincoln-primary-color;
    border-left: 0.25rem solid var(--fds-color--secondary);
    margin-right: 1rem;
  }

  .answer {
    margin-bottom: 2em;
    color: var(--fds-color--black);
    border: 1px transparent solid;
  }

  .common-faq-row {
    border-radius: 5px;
    padding: 0.5em 0.5em;
    display: flex;
    width: fit-content;
  }

  .lincoln-common-faq-row {
    padding: 0.5em 0.5em;
    display: flex;
    width: fit-content;
  }

  .page-header {
    padding: 1.5em 1em;
    font-weight: normal;
  }

  .lead-letter {
    font-weight: bold;
    margin-right: 1em;
  }

  .faq-text {
    text-align: left;
  }

  .content-width {
    width: 80%;
    display: inline-block;
  }
}
