.warning-message-container {
  justify-content: center;
}

.warning-message-inner-container {
  max-width: 80%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1em;
}

.warning-message-text {
  vertical-align: top;
  color: var(--fds-color--error1);
  font-size: 18px;
  font-weight: 500;
}

.lincoln-warning-message {
  margin: 3rem 0;

  .warning-message-text {
    font-size: 20px;
    color: var(--fds-color--secondary);
    font-family: var(--fmc-font--miller);
  }
}

.confirmation-message-container {
  text-align: center;
  justify-content: center;
}

.confirmation-button {
  margin: 1em;
}

.trash-can-icon {
  height: 1.1em;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  padding: 1px;
  vertical-align: top;
  margin-left: 0.2em;
  color: var(--fds-color--primary);
}

.urgent-communication-icon {
  height: 1.1em;
  opacity: 0.54;
}
