@import "globalVariables";

.supplementalVehicleBody {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  grid-gap: 1em;
}

.supplementalVehicleHeader {
  width: 90%;
  margin: 4vh auto 2vh;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  white-space: nowrap;
  grid-gap: 13px;
  justify-content: space-between;
}

.supplementalVehicleTitle {
  grid-column: 2;
  text-align: center;
}

.svr-tooltip {
  text-align: right;
  line-height: 0.8em;
}

.svr-tooltip .multi-line {
  text-align: left !important;
}

.success-message-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
}

.svr-selection-button {
  border: var(--fds-color--primary) solid 1.5px;
  border-radius: 4px;
  margin: 0 5px 0 5px;
  padding: 2px 10px 2px 10px;
  background: var(--fds-color--fourth);
}

.svr-input-field {
  border: var(--fds-color--primary) solid 1.5px;
  border-radius: 4px;
  width: 3em;
  text-align: center;
  font-size: 1.6rem;
  color: $primary-color;
  padding: 2px 0 2px 0;
}

[data-brand="lincoln"] .svr-input-field {
  border: var(--fds-color--primary) solid 1.5px;
  border-radius: 0;
  width: 3em;
  text-align: center;
  font-size: 1.6rem;
  color: $lincoln-primary-color;
  padding: 2px 0 2px 0;
}

[data-brand="lincoln"] .svr-selection-button {
  border: var(--fds-color--primary) solid 1.5px;
  border-radius: 0;
  margin: 0 5px 0 5px;
  padding: 2px 10px 2px 10px;
  background: white;
}

.svr-increment-decrement-buttons {
  font-size: 1.6rem;
}
