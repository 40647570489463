.timer-box {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.time-remaining {
  margin-bottom: 10px;
}

.timer-text {
  text-align: left;
  margin: 0 8px 0 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.vehicle-offering-header {
  font-size: 20px;
}

.reallocation-timer-text {
  color: var(--fds-color--error1);
}
