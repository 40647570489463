.lpivw-font-family__proxima-nova {
  font-family: var(--fmc-font--proxima-nova);
}

.accept-reject {
  min-width: 225px;
}

//body {
//  background-color: var(--fds-color--gray3);
//}
