.timer-offering-status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
}

.offering-status {
  flex-direction: column;
  justify-content: space-around;
}

.offering-status-heading {
  font-size: 20px;
}

.offering-status-body {
  text-align: left;
  display: grid;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: "remainingVehicles totalVehicles description";
  grid-gap: 0.3em;
}

.offering-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "actionedVehicles totalvehicle actionTaken"
    "actionedVehicles totalVehicle actionTaken";
  grid-gap: 0.3em;
}

.vehicle-response-count {
  font-weight: bold;
  text-align: right;
}

.offering-wording {
  text-align: left;
}

.sup {
  line-height: 2em;
}
