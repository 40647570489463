@import "../globalVariables";

@media (min-width: $breakpoint-desktop) {
}

.header-lincoln {
  background-color: var(--fds-color--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}

.lincoln-header-box {
  width: 95%;
  margin-left: auto;

  .userDropdown {
    border-top: 1.5px solid var(--fds-color--primary);
    transform: scale(1);
    transition:
      box-shadow 0.3s ease-in-out,
      transform 0.3s ease-in-out,
      background-color 0.3s linear,
      border-color 0.3s linear;
    &:hover {
      border-top: 1.5px solid var(--fds-color--secondary);
    }
  }
  .lincolnBorder {
    border-top: 1.5px solid var(--fds-color--secondary);
  }
}

.header-logo-lincoln {
  padding: 0.65rem 0 0 0;
  float: left;
}

.header-content {
  display: flex;
  float: right;
}

.menu-button-icons {
  width: 45%;
}

.home {
  padding: 1em 0.75em 1em 0.75em;
  border-color: var(--fds-color--white);
  border-width: 1px;
  border-style: none solid none solid;
  color: var(--fds-color--white);
}
