.model-e-dropdowns-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: flex-start;
}

.selected-button {
  --bg-color: var(--fds-color--success2);
  --bg-color-hover: var(--fds-color--success2);
  --bg-color-active: var(--fds-color--success1);
  --bg-color-outlined-hover: var(--fds-color--success2);
  --bg-color-outlined-active: var(--fds-color--success2);
  --border-color-outlined: var(--fds-color--success2);
  --border-color-outlined-hover: var(--fds-color--success2);
}

.selected-button:focus {
  --bg-color: var(--fds-color--success2);
}

.selected-button:focus:not(:focus-visible) {
  --bg-color: var(--fds-color--success2);
  --shadow: 1;
}

.xIcon {
  height: 1em;
  padding-top: 0.3em;
  padding-right: 0.2em;
}

.clearFilters-Mechanism {
  color: #1400ff;
  font-family: var(--fmc-font--antenna);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  padding-left: 0.3em;
}

.clear-filter-holder {
  display: flex;
}

.filter-row {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: flex-start;
}

.incentive-container {
  padding: 28px 14px 14px;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: center;
}

.incentive-wrapper {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 4px;
}

.modal-header {
  z-index: 2;
  padding-bottom: 0;

  .fmc-type--subtitle1 {
    color: var(--fds-color--black);
  }
}

.fmc-dialog {
  z-index: 2;
}

.fmc-dialog__content {
  max-width: fit-content;
}
