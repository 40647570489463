@import "globalVariables";

@media (min-width: $breakpoint-desktop) {
}

.greetings {
  font-style: italic;
  color: var(--fds-color--white);
  padding: 1em 0.5em 1em 0.5em;
  border-color: var(--fds-color--white);
  border-width: 1px;
  border-style: none solid none none;
}

.header {
  background-color: var(--fds-color--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}

.header-logo {
  height: 3em;
}

.header-content {
  display: flex;
}

.menu-button-icons {
  width: 45%;
}

.home {
  padding: 1em 0.75em 1em 0.75em;
  border-color: var(--fds-color--white);
  border-width: 1px;
  border-style: none solid none none;
  color: var(--fds-color--white);
}

.home-logo {
  padding-left: 0.5em;
}

.white-font {
  font-size: 16px;
  color: var(--fds-color--white);
  display: inline-flex;
}
.white-font:hover {
  color: var(--fds-color--white);
}

.menu-icon {
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-flex;
}

.menu-icon-spacing {
  margin-left: 0.25em;
}
