.rr-table {
  --text-color: var(--fds-color--black);
  --type-weight: var(--fmc-type__weight-normal);
  --background-color: #e5e5e5;
  --background-alternate-color: var(--fds-color--fourth);
  --border-width: 0;
  --border-color: var(
    --fds-color--gray2
  ); // NOTE: border color is not specified in invision
  --header-background-color: #d9d9d9;
  --header-text-color: var(--fds-color--black);
  --header-border-color: var(--fds-color--white);
  --header-type-weight: normal;
  width: 90%;
  border-collapse: collapse;
  margin: auto;

  th:first-of-type {
    border-top-left-radius: 0.5em;
  }

  th:last-of-type {
    border-top-right-radius: 0.5em;
  }

  th,
  td {
    border: var(--border-width) solid var(--border-color);
    background-color: var(--background-color);
    color: var(--text-color);
    font-weight: var(--type-weight);
    vertical-align: center;
    height: 3.06em;
  }

  %th-styles {
    --border-color: var(--header-border-color);
    --background-color: var(--header-background-color);
    --text-color: var(--header-text-color);
    --type-weight: var(--header-type-weight);
    height: 3.19em;
    border-top-color: var(--background-color);

    &:first-child {
      border-left-color: var(--background-color);
    }

    &:last-child {
      border-right-color: var(--background-color);
    }
  }

  th {
    @extend %th-styles;
  }

  &.rr-table--alternating-rows {
    tr:nth-child(odd) {
      --background-color: var(--background-alternate-color);
    }
  }

  &.rr-table--full-width {
    width: 100%;

    table {
      width: 100%;
    }
  }
}

.order-history-width {
  width: 80%;
}
