.salesCodeContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.sales-code-dropdown {
  padding-top: 0;
  padding-right: 15px;
}

.hidden {
  display: none;
}

.warning-icon {
  color: var(--fds-color--caution2);
}

.lincoln-warning-icon {
  color: var(--fds-color--secondary);
}

.salesCodeSelectWarning {
  font-size: 14px;
}
