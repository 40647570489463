@use "~ag-grid-community/styles" as ag;
@import "globalVariables";

.ag-theme-alpine {
  @include ag.grid-styles(
    (
      (
        // use theme parameters where possible
        alpine-active-color: darkblue,
        header-background-color: #efefef
      )
    )
  );

  .ag-row-odd {
    background-color: var(--fds-color--fourth);
  }

  .ag-header {
    // or write CSS selectors to make customisations beyond what the parameters support
    font-style: italic;
    background-image: linear-gradient(var(--fds-color--fourth), #ececec);
  }
}

.accept-reject-button {
  --shadow: none;
  min-width: 120px;
  &:hover,
  &:focus {
    --shadow: none !important;
  }
  display: block;
  margin: 6px auto;
}

table {
  width: 90%;
  margin: auto;
  border-spacing: 0;
}

.fmc-table th,
.fmc-table td {
  vertical-align: middle;
  width: max-content;
}

.fmc-table tr {
  //height: 5em;
}

.ReactModal__Overlay {
  z-index: 25;
}

.close-button {
  float: right;
}

.ReactModal__Content {
  width: 70%;
  margin: auto;
  height: fit-content;
}

@media (min-width: 768px) {
  .ReactModal__Content {
    width: 40%;
  }
}

@media (min-width: 480px) {
  .ReactModal__Content {
    width: 50%;
  }
}

.warning-modal-container {
  display: flex;
  flex-direction: column;
}

.modal-header {
  text-align: center;
  padding-bottom: 10px;
  color: var(--fds-color--primary);
}

.lincoln-modal-header {
  text-align: center;
  padding-bottom: 10px;
  font-size: 2.4rem;
  color: var(--fds-color--primary);
}

.lincoln-warning-modal-description {
  text-align: center;
  color: var(--fds-color--error1);
}

.center-content {
  flex: 100%;
  display: flex;
  justify-content: center;
}

.modal-description {
  margin: 1% 5% 1% 5%;
}

.modal-text {
  margin: 0 10% 0 10%;
}

.warning-success {
  text-align: center;
}

.modal-text::placeholder {
  opacity: 0.5;
}

.fmc-button {
  --shadow: 0;
  --radius: 0.5em;
  --height: 3rem;
}

.fmc-dialog__content {
  border: solid var(--fds-color--gray2) 1px;
  border-radius: 0.5em;
}

.lincoln-dialog__content {
  border: solid var(--fds-color--primary) 1px;
  border-radius: 0;
}

h3 {
  color: var(--fds-color--black);
}

.page-header {
  margin: 2rem;
}

.page-subject {
  margin: 2rem;
  color: var(--fds-color--black);
}

.contact-us-font {
  color: var(--fds-color--black);
}

.link-styling,
.link-styling:hover {
  color: $primary-color;
}
//body {
//  background-color: var(--fds-color--gray1);
//}
