.confirmation-modal {
  z-index: 25;
  text-align: left;
}

.submit-button {
  --shadow: none;

  &:hover,
  &:focus {
    --shadow: none;
  }
}

.center {
  text-align: center;
}
