@import "Grid.module";

.orderDetails {
  text-align: left;
  display: flex;
  overflow-x: auto;
  padding: 10px;
}

.strong {
  font-weight: bold;
}

.packagesSpacing {
  margin-right: 20px;
}

.historyIcon {
  height: 1em;
  vertical-align: 0.25em;
  padding-left: 0.25em;
}

.orderStatus {
  display: inline-flex;
  align-items: center;
}
