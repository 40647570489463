.reallocation-guide {
  margin: 1em auto 0 auto;
  max-width: 75%;
  justify-content: center;
  justify-self: center;
  border: 1px solid var(--fds-color--primary);
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  color: var(--fds-color--primary);
  padding: 0.25em;
}
