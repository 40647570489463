@import "globalVariables";

.user-profile-link-container {
  margin-top: 1em;

  p {
    font-size: 1.2rem;
  }
}

.permissions-text {
  color: var(--fds-color--error1);
  margin-bottom: 1em;
}

.permissions-container {
  display: flex;
  margin-top: 25vh;
  margin-left: 25vw;
  width: 50vw;
  flex-direction: column;
  justify-content: space-between;
}

.permissions-instruction {
  display: flex;
  flex-direction: column;
}

.permissions-contact {
  color: black;
  font-size: 1.6rem;
  margin-bottom: 1em;
}

.external-link {
  color: blue;
  text-decoration: underline;
}

.loading-indicator-position {
  margin-top: 25vh;
}

.portal-name {
  margin-right: 5px;
  font-size: 20px;
}

.portal-sub-header {
  font-size: 15px;
  padding-top: 15px;
  display: flex;
  white-space: pre-line;
}

.lincoln-portal-name {
  font-family: var(--fmc-font--miller);
}

.submit-age-modal-body-text {
  text-align: center;
}

.submit-age-modal-header-text {
  text-align: center;
  padding-bottom: 15px;
}

.portal-header {
  margin: 4vh auto 2vh;
  padding: 0 0 0 5vw;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  float: left;
}

.portal-header-history {
  margin: 4vh auto 2vh;
  padding: 0 0 0 5vw;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
}

.admin-tools {
  display: flex;
  float: right;
  margin: 4vh auto 2vh;
  padding: 0 5vw 0 0;
  align-items: center;
}

.order-thing {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.age-config-container {
  margin: 4vh auto 2vh;
  padding: 0 0 0 5vw;
  text-align: left;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-between;
  float: left;
}

.age-config-text {
  font-size: 15px;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  white-space: pre-line;
  padding-right: 8px;
}

.age-config-text-right {
  padding-left: 8px;
}

.responsive-input {
  max-width: 50px;
  width: auto;
  color: gray;
  font-size: 15px;
  text-align: center !important;

  :checked,
  :active,
  &:focus {
    color: black;
  }
}

.input-used {
  color: black;
}

.timer-sales-code {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}

.table-box-header {
  width: fit-content;
  border: #181d1f solid 1px;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background: var(--fds-color--fourth);
  border-radius: 15px;
}

.lincoln-table-box-header {
  width: fit-content;
  border: var(--fds-color--black) solid 1px;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  background: #f1f2f2;
}

.dropdowns-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: flex-start;
}

.filter-container {
  display: flex;
  gap: 0.5em;
  padding-top: 1em;
  justify-content: space-between;
}

.admin-tool-button {
  --shadow: none;
  margin-left: 1vw;
  height: 3rem;

  &:hover,
  &:focus {
    --shadow: none !important;
  }
}

.title-and-tooltip {
  display: flex;
  align-items: center;
}

.bottom-submit-and-contact {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-areas: "blank approve contact-us";
}

.submitButton {
  grid-area: approve;
  margin-top: 20px;
  margin-bottom: 5px;
}

.userDropdown {
  color: var(--fds-color--fourth);
  padding: 1em 0.5em 1em 0.5em;
  display: flex;
  align-items: center;
}

.menuTitle {
  padding-right: 5px;
}

.contact-us-icon {
  height: 6vh;
  padding: 30% 10%;
  vertical-align: middle;
  float: right;
}

.contact-us-container {
  display: grid;
}

.delayed-offering {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
  padding-top: 1em;
}

.delayed-offering > p {
  color: var(--fds-color--error2);
}

.confirmation-modal {
  color: $primary-color;
}

.contact-us-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.red-asterisk {
  color: var(--fds-color--error1);
}

.cdsid-input {
  flex: 0 0 45%;
}

.sales-code-input {
  flex: 0 0 45%;
}

.email-address-input {
  flex: 0 0 45%;
}

.description-textarea {
  outline-color: var(--fds-color--error1);
  border-radius: 6px;
  width: 100%;
  height: 5em;
  padding: 2%;
  border: 2px solid;
  resize: vertical;
}

.contact-us-input-margin {
  width: 100%;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

.contact-us-alert {
  border-radius: 6px;
  border: 2px solid var(--fds-color--error1);
  width: 100%;
}

.contact-us-alert-text {
  color: var(--fds-color--caution2);
}

.lincoln-contact-us-alert-text {
  color: var(--fds-color--secondary);
}

.contact-us-input-style {
  border-radius: 6px;
  height: 3em;
}

.modal-margins {
  margin-bottom: 1em;
}

@media (min-width: $breakpoint-desktop) {
  .userDropdown {
  }
}
